<template>
  <div>
    <b-card no-body>
      <b-tabs pills card>
        <b-tab title="Umumiy malumotlar"
          ><b-card-text>
            <div class="input__wrapper">
              <div class="input col-3 pl-0">
                <label>FIO</label>
                <input
                  type="text"
                  disabled
                  class="input-text"
                  :value="getKontragent.full_name"
                />
              </div>

              <div class="input col-3">
                <label>INN</label>
                <input
                  type="text"
                  disabled
                  class="input-text"
                  :value="getKontragent.inn"
                />
              </div>

              <div class="input col-3">
                <label>Telefon raqam</label>
                <input
                  type="text"
                  disabled
                  class="input-text"
                  :value="getKontragent.phone"
                />
              </div>

              <div class="input col-3 pr-0">
                <label>Yuridik turi</label>
                <input
                  type="text"
                  disabled
                  class="input-text"
                  :value="getKontragent.juridik_adress"
                />
              </div>
            </div> </b-card-text
        ></b-tab>

        <b-tab title="Shartnomalar"
          ><b-card-text>
            <v-data-table
              no-data-text="Malumotlar topilmadi!"
              loading-text="Malumotlar yuklanmoqda!"
              :headers="headers"
              :items="getDetails"
              hide-default-footer
              :items-per-page="10"
            >
              <template v-slot:[`item.index`]="{ item }">
                {{
                  getDetails
                    .map(function (x) {
                      return x.id
                    })
                    .indexOf(item.id) + 1
                }}
              </template>
            </v-data-table>
          </b-card-text></b-tab
        >
        <b-tab title="Boshlang'ich qoldiq">
          <div class="row justify-content-end">
            <div class="col">
              <v-btn
                @click="dialog = true"
                :loading="isLoading"
                small
                color="primary"
              >
                +
              </v-btn>
            </div>
          </div>
          <b-card-text>
            <v-data-table
              :headers="startingHeaders"
              no-data-text="Malumotlar kiritilmagan"
              :items="getStartingPoint"
              hide-default-footer
            >
              <template v-slot:[`item.oper_date`]="{ item }">
                {{ item.oper_date | dateFilter }}
              </template>
              <template v-slot:[`item.debet_amount`]="{ item }">
                {{ item.debet_amount | mask }}
              </template>
              <template v-slot:[`item.credit_amount`]="{ item }">
                {{ item.credit_amount | mask }}
              </template>
              <template v-slot:[`item.action`]="{ item }">
                <button
                  type="button"
                  @click="edit(item)"
                  class="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                >
                  <span class="svg-icon svg-icon-md svg-icon-primary">
                    <!--begin::Svg Icon | path:/metronic/theme/html/demo1/dist/assets/media/svg/icons/Communication/Write.svg-->
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      version="1.1"
                    >
                      <g
                        stroke="none"
                        stroke-width="1"
                        fill="none"
                        fill-rule="evenodd"
                      >
                        <rect x="0" y="0" width="24" height="24"></rect>
                        <path
                          d="M12.2674799,18.2323597 L12.0084872,5.45852451 C12.0004303,5.06114792 12.1504154,4.6768183 12.4255037,4.38993949 L15.0030167,1.70195304 L17.5910752,4.40093695 C17.8599071,4.6812911 18.0095067,5.05499603 18.0083938,5.44341307 L17.9718262,18.2062508 C17.9694575,19.0329966 17.2985816,19.701953 16.4718324,19.701953 L13.7671717,19.701953 C12.9505952,19.701953 12.2840328,19.0487684 12.2674799,18.2323597 Z"
                          fill="#000000"
                          fill-rule="nonzero"
                          transform="translate(14.701953, 10.701953) rotate(-135.000000) translate(-14.701953, -10.701953)"
                        ></path>
                        <path
                          d="M12.9,2 C13.4522847,2 13.9,2.44771525 13.9,3 C13.9,3.55228475 13.4522847,4 12.9,4 L6,4 C4.8954305,4 4,4.8954305 4,6 L4,18 C4,19.1045695 4.8954305,20 6,20 L18,20 C19.1045695,20 20,19.1045695 20,18 L20,13 C20,12.4477153 20.4477153,12 21,12 C21.5522847,12 22,12.4477153 22,13 L22,18 C22,20.209139 20.209139,22 18,22 L6,22 C3.790861,22 2,20.209139 2,18 L2,6 C2,3.790861 3.790861,2 6,2 L12.9,2 Z"
                          fill="#000000"
                          fill-rule="nonzero"
                          opacity="0.3"
                        ></path>
                      </g>
                    </svg>
                    <!--end::Svg Icon-->
                  </span>
                </button>
                <button
                  @click="deletePoint(item.id)"
                  type="button"
                  class="btn btn-icon btn-light btn-hover-primary btn-sm"
                >
                  <span class="svg-icon svg-icon-md svg-icon-primary">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      version="1.1"
                    >
                      <g
                        stroke="none"
                        stroke-width="1"
                        fill="none"
                        fill-rule="evenodd"
                      >
                        <rect x="0" y="0" width="24" height="24"></rect>
                        <path
                          d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z"
                          fill="#000000"
                          fill-rule="nonzero"
                        ></path>
                        <path
                          d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                          fill="#000000"
                          opacity="0.3"
                        ></path>
                      </g>
                    </svg>
                  </span>
                </button>
              </template>
            </v-data-table> </b-card-text
        ></b-tab>
      </b-tabs>
    </b-card>
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline"> Boshlang'ich qoldiq kiritish </span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <v-menu
                  v-model="menu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      dense
                      outlined
                      v-model="date"
                      label="Sana"
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="date"
                    @input="menu2 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  label="Debet"
                  v-model="debet"
                  v-currency="options"
                  outlined
                  dense
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  v-currency="options"
                  outlined
                  dense
                  v-model="credit"
                  label="Credit"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="
              dialog = false
              creating = true
            "
          >
            Bekor qilish
          </v-btn>
          <v-btn :loading="isLoading" color="blue darken-1" text @click="send">
            Saqlash
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import Swal from 'sweetalert2'
export default {
  data() {
    return {
      editingId: '',
      creating: true,
      debet: '',
      credit: '',
      selectedCurrencyOption: 1,
      currencyCode: 'USD',
      distractionFree: false,
      hideCurrencySymbol: true,
      hideGroupingSymbol: true,
      hideNegligibleDecimalDigits: true,
      prefix: null,
      suffix: null,
      precisionEnabled: true,
      precisionRangeEnabled: false,
      precisionFixed: 0,
      precisionRange: [0, 20],
      valueRangeEnabled: false,
      valueRange: [0, 9999],
      date: '',
      menu2: false,
      dialog: false,
      id: '',
      headers: [
        {
          text: '#',
          value: 'index'
        },
        {
          text: 'Reg raqam',
          value: 'reg_number'
        },
        {
          text: 'Reg sana',
          value: 'reg_date'
        },

        {
          text: 'Tugash sana',
          value: 'end_date'
        },
        {
          text: 'Shartnoma turi',
          value: 'contract_type_name'
        }
      ]
    }
  },
  created() {
    this.id = this.$route.params.id
    this.$store.dispatch('counteragentContracts', this.$route.params.id)
    this.$store.dispatch('getCountragentStartingPoint', this.$route.params.id)
    this.$store.dispatch('getKontragentsContractsListWithoutPg')
  },
  filters: {
    mask: (val) => {
      if (Boolean(val) && typeof val !== 'string') {
        return val.toLocaleString('es-US')
      }
    }
  },
  computed: {
    options() {
      return {
        locale: 'zh-ZH',
        currency: [null],
        valueRange: this.valueRangeEnabled
          ? { min: this.valueRange[0], max: this.valueRange[1] }
          : undefined,
        precision: this.precisionEnabled
          ? this.precisionRangeEnabled
            ? { min: this.precisionRange[0], max: this.precisionRange[1] }
            : this.precisionFixed
          : undefined,
        distractionFree: this.distractionFree,
        autoDecimalMode: this.autoDecimalMode,
        valueAsInteger: this.valueAsInteger,
        allowNegative: this.allowNegative
      }
    },
    startingHeaders() {
      return [
        {
          text: 'ID',
          value: 'id'
        },
        {
          text: 'Sana',
          value: 'oper_date'
        },
        {
          text: 'Debet',
          value: 'debet_amount'
        },
        {
          text: 'Kredit',
          value: 'credit_amount'
        },
        {
          text: '',
          value: 'action'
        }
      ]
    },
    getDetails() {
      return this.$store.state.requests.counteragentContracts
    },
    getStartingPoint() {
      return this.$store.state.requests.allCountragentStartingPoint.results
    },
    isLoading() {
      return this.$store.state.requests.isLoading
    },
    getKontragent() {
      const data2 = {}
      const data = this.$store.state.requests.kontragetnsWithoutPg
      data.forEach((element) => {
        if (element.id == this.$route.params.id) {
          Object.assign(data2, element)
        }
      })
      return data2
    }
  },
  methods: {
    edit(payload) {
      this.editingId = payload.id
      this.creating = false
      this.dialog = true
      this.date = payload.oper_date
      this.debet = payload.debet_amount.toLocaleString('es-US')
      this.credit = payload.credit_amount.toLocaleString('es-US')
    },
    deletePoint(payload) {
      Swal.fire({
        title: 'Tasdiqlang',
        text: "Ma'lumot ochrilganidan so'ng qayta tiklay olmaysiz!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: "Xa, o'chirish",
        cancelButtonText: 'Bekor qilish'
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire('Ochirildi!', '', 'success')
          this.$store.dispatch('deleteCountragentStartingPoint', {
            id: payload,
            client: this.$route.params.id
          })
        }
      })
    },
    send() {
      if (this.date !== '' && this.debet !== '' && this.credit !== '') {
        const data = {
          client: this.$route.params.id,
          oper_date: this.date,
          debet_amount: parseInt(this.debet.replace(/[ ,]/g, ''), 10),
          credit_amount: parseInt(this.credit.replace(/[ ,]/g, ''), 10)
        }
        if (this.creating) {
          this.$store
            .dispatch('createCountragentStartingPoint', data)
            .then(() => {
              this.dialog = false
              this.date = ''
              this.debet = ''
              this.credit = ''
            })
        } else {
          this.$store
            .dispatch('updateCountragentStartingPoint', {
              data: data,
              id: this.editingId
            })
            .then(() => {
              this.dialog = false
              this.date = ''
              this.debet = ''
              this.credit = ''
            })
        }
      } else {
        Swal.fire({
          title: '',
          text: 'Formada xatolik bor',
          icon: 'error',
          confirmButtonClass: 'btn btn-secondary'
        })
      }
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Kontragentlar' },
      { title: 'Kontragent malumotlari' }
    ])
  }
}
</script>

<style scoped>
.input__wrapper {
  display: flex;
}
.input label {
  display: block;
  font-size: 13px;
}
.input {
  margin-right: 6px;
  margin-bottom: 30px;
}
.input:last-child {
  margin-right: 0;
}
.input-text {
  width: 100%;
  background-color: rgb(142 190 253 / 14%);
  padding: 1rem;
  border: 1px solid rgb(142 190 253 / 28%);
  color: inherit;
  font-size: 16px;
  border-radius: 3px;
}
</style>
